/* ==================================================================================================================
 * OpenGoSim Bluebell: app/app.store.constants.ts
 * Copyright 2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

export const CLEAR_ALL_STORES = 'CLEAR_ALL_STORES';

export class ClearAllStoresAction implements Action {
  readonly type = CLEAR_ALL_STORES;
}

export const REFRESH_ALL = 'REFRESH_ALL';

export class RefreshAllAction implements Action {
  readonly type = REFRESH_ALL;
}
